import { EventEmitter } from '@angular/core';

import get from 'lodash-es/get';

interface ChoropletOpts {
  palette: string | string[]; // Chroma.js palette color or array of hex colors
  defaultcolor?: string;
}

const ss = require('simple-statistics');
const chroma = require('chroma-js');

export class MapboxPlusChoroplet {

  private legends = {};
  public legends$ = new EventEmitter<any>();

  constructor() {}

  makeChoroplet(mapstyle, layer, opts, data) {

    // console.log('makeChoropleths', layer.id, data.length);

    // retrieve value/key pair
    const dataKey = get(opts, 'data.key');
    const dataVal = get(opts, 'data.value');

    const defaultColor = opts.defaultcolor || 'transparent';

    // console.log(dataKey, dataVal, opts.featkey);

    let legend = [];
    const STOPS = [];

    switch (opts.type) {
      case 'categories': {
        // console.log('category rows', layer.id, data);
        const colors = chroma.scale(opts.palette).colors(opts.categories.length);
        const categoryColorPair = this.makeCategoryColorPair(opts.categories, colors);

        // console.log(layer.id, 'categoryColorPair', categoryColorPair);

        // init class counter object
        const classCount = colors.reduce((map, obj) => { map[obj] = 0; return map; }, {});
        classCount[defaultColor] = 0;

        // generate dataKey/color stop pairs
        data.forEach(row => {
          // const pIdx = isNaN(Number(row[propIdx])) ? row[propIdx] : Number(row[propIdx]);
          const color = categoryColorPair[row[dataVal]] || defaultColor;
          STOPS.push([row[dataKey], color ]);
          classCount[color]++;
        });
        // console.log('---------------------');
        // console.log(STOPS);
        // console.log('---------------------');

        // console.log('classCount', layer.id, classCount);
        legend = this.makeLegend(opts.categories, opts.palette, classCount);
        break;
      }
      case 'ckmeans': {
        // filter rows with spurious data
        const numRows = data.map(row => get(row, dataVal)).filter(Number.isFinite);
        if (!Array.isArray(numRows) || !numRows.length) {
          console.error('No values found for ' + dataVal + '. Please check the config or data for ' + layer.id);
          return;
        }

        // console.log(layer.id, 'rows', numRows);

        // compute optimal class breaks
        const CK = this.ckmeans(numRows, opts.breaks);

        // compute palette
        const colors = chroma.scale(opts.palette).colors(CK.breaks.length - 1);

        // init class counter object
        const classCount = colors.reduce((map, obj) => { map[obj] = 0; return map; }, {});
        classCount[defaultColor] = 0;

        // console.log('classCount before', layer.id, {...classCount});

        // define a color scale
        const colorScale = chroma
          .scale(colors)
          .domain(CK.breaks)
          .classes(CK.breaks);

        // console.log(CK, colors, data);
        // prepare id/value pairs
        if (opts.featkey_type === 'string') {
          data.forEach(row => {
            const color = colorScale(get(row, dataVal)).hex();
            STOPS.push([String(row[dataKey]), color]);
            classCount[color]++;
          });
        } else {
          data.forEach(row => {
            const color = colorScale(get(row, dataVal)).hex();
            STOPS.push([Number(row[dataKey]), color]);
            classCount[color]++;
          });
        }


        // console.log('classCount after', layer.id, classCount);
        legend = this.makeLegend(CK.breaks, colors, classCount);
        break;
      }
      case 'equalinterval': {
        // TODO: refactor to support dataVal path
        // filter rows with spurious data
        const numRows = data.map(row => row[dataVal]).filter(Number.isFinite);

        // compute equal distance class breaks
        const EQ = this.equalinterval(numRows, opts.breaks, opts.min, opts.max);
        const colors = chroma.scale(opts.palette).colors(EQ.breaks.length);

        // init class counter object
        const classCount = colors.reduce((map, obj) => { map[obj] = 0; return map; }, {});
        classCount[defaultColor] = 0;

        // define a color scale
        const colorScale = chroma
          .scale(opts.palette)
          .domain([EQ.min, EQ.max])
          .classes(EQ.breaks);

        // prepare id/value pairs
        data.forEach(row => {
          const color = colorScale(row[dataVal]).hex();
          STOPS.push([Number(row[dataKey]), color]);
          classCount[color]++;
        });

        legend = this.makeLegend(EQ.breaks, colors, classCount);
        break;
      }
    }

    // prepare new layer paint style
    layer.paint['fill-color'] = {
      property: opts.featkey,
      stops: STOPS,
      'type': 'categorical',
      'default': defaultColor,
    };

    this.legends[layer.id] = legend;
  }

  makeCategoryColorPair (categories: string[], colors: string[]) {
    const cObj = {};
    for (let i = 0; i < categories.length; i++) {
      cObj[categories[i]] = colors[i];
    }
    return cObj;
  }

  makeLegend (labels: string[], colors: string[], classCount: any) {

    const legend = [];
    for (let i = 0; i < labels.length; i++) {
      // console.log(colors[i]);
      legend.push({label: labels[i], color: colors[i], count: classCount[colors[i]]});
    }
    return legend;
  }

  ckmeans(vals: number[], binCount: number) {
    const bins = ss.ckmeans(vals, Math.min(binCount, vals.length))
      .map(bin => [bin[0], bin[bin.length - 1]]);

    return {
      min: bins[0][0],
      max: bins[bins.length - 1][1],
      breaks: [bins[0][0], ...bins.map(b => b[1])],
    };
  }

  equalinterval(vals: number[], binCount: number, min?: number, max?: number) {
    if (!min) {
      min = ss.min(vals);
    }
    if (!max) {
      max = ss.max(vals);
    }
    const breaks = ss.equalIntervalBreaks([min, max], binCount);

    return {
      min: min,
      max: max,
      breaks: breaks,
    };
  }

  checkOptions(layer) {
    const opts = get(layer, 'metadata.mbp:choropleth');

    // no choroplet for this layer, exit
    if (!opts) {
      return false;
    }

    // CHECK PALETTE
    if (!opts.palette) {
      // missing
      throw new Error(`option metadata.mbp:choropleth.palette for layer ${layer.id} is missing`);
    }

    try {
      chroma.scale(opts.palette);
    } catch (err) {
      // can't be parsed by chroma-js
      throw new Error(`option metadata.mbp:choropleth.palette for layer ${layer.id} is invalid`);
    }

    // CHECK DEFAULT COLOR
    if (opts.defaultcolor && !chroma.valid(opts.defaultcolor)) {
      // can't be parsed by chroma-js
      throw new Error(`option metadata.mbp:choropleth.defaultcolor for layer ${layer.id} is invalid`);
    }

    // CHECK VALID TYPE
    if (!opts.type || ['ckmeans', 'equalinterval', 'categories'].indexOf(opts.type) === -1) {
      // type must be one of ckmeans|equalinterval|categories
      throw new Error(`option metadata.mbp:choropleth.type for layer ${layer.id} isn\'t ckmeans|equalinterval|categories`);
    }

    // TYPE !== CATEGORIES
    if (opts.type !== 'categories') {
      if (typeof opts.breaks !== 'number') {
        // breaks is not a number
        throw new Error(`option metadata.mbp:choropleth.type = ${opts.type}
        for layer ${layer.id} metadata.mbp:choropleth.breaks to be a number`);
      }
    }

    // TYPE !== EQUALINTERVAL
    if (opts.type !== 'equalinterval') {
      if (opts.min != null || opts.max != null) {
        // min/max can be used only in combination with equalinterval
        throw new Error(`option metadata.mbp:choropleth.min|max for layer ${layer.id} is invalid with type "categories|ckmeans"`);
      }
    }

    // TYPE === CATEGORIES
    if (opts.type === 'categories') {
      if (!Array.isArray(opts.categories)) {
        // needs an array of categories
        throw new Error(`option metadata.mbp:choropleth.type ${opts.type}
        for layer ${layer.id} metadata.mbp:choropleth.categories to an array`);
      }
      // check palette vs categories
      if (Array.isArray(opts.palette)) {
        // a palette array must have the same length of categories
        if (opts.palette.length !== opts.categories.length) {
          throw new Error(`option metadata.mbp:choropleth.palette for layer ${layer.id}
           differs in length from metadata.mbp:choropleth.categories`);
        }
      } else {
        try {
          // a string palette parsed with chroma-js should produce a palette array the same length of categories
          chroma.scale(opts.palette).colors(opts.categories.length);
        } catch (err) {
          throw new Error(`option metadata.mbp:choropleth.palette for layer ${layer.id} is invalid for metadata.mbp:choropleth.categories`);
        }
      }

    }

    // CHECK DATA PARAMETERS
    if (opts.data) {
      ['url', 'key', 'value'].forEach(arg => {
        if (!opts.data[arg] || typeof opts.data[arg] !== 'string') {
          // must use url|key|value parameters
          throw new Error(`option metadata.mbp:choropleth.data ${arg} for layer ${layer.id} is missing or invalid`);
        }
      });
    }

    // CHECK VALID FEATKEY
    if (typeof opts.featkey !== 'string') {
      // the feature key must be specified
      throw new Error(`option metadata.mbp:choropleth.featkey for layer ${layer.id} is missing or invalid`);
    }

    // CHECK VALID FEATKEY
    if (opts.featkey_type && opts.featkey_type !== 'string' && opts.featkey_type !== 'number') {
      // the feature key must be specified
      throw new Error(`option metadata.mbp:choropleth.featkey_type for layer ${layer.id} is invalid`);
    }

    return opts;
  }
}
