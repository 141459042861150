import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { NgcCookieConsentModule } from 'ngx-cookieconsent';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';


import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { SharedModule } from '@shared/shared.module';
import {FormsModule} from '@angular/forms';


@NgModule({
    declarations: [
        HeaderComponent,
        FooterComponent
    ],
  exports: [
    HeaderComponent,
    FooterComponent
  ],
    imports: [
        CommonModule,
        NgcCookieConsentModule,
        BsDropdownModule.forRoot(),
        CollapseModule.forRoot(),
        NgSelectModule,
        NgOptionHighlightModule,
        RouterModule,
        SharedModule,
        FormsModule
    ]
})
export class LayoutModule { }
