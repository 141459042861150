<div class="container-narrow mt-5">
    <div class="d-flex justify-content-center">
        <select class="form-select w-auto me-4" aria-label="Seleziona tipo di centro" [(ngModel)]="postiDisponibiliType" (change)="postiDisponibiliRefresh()">
            <option value="">Tutti i centri</option>
            <option value="CAS">CAS</option>
            <option value="SAI">SAI</option>
            <option value="PRIMA_ACCOGLIENZA">PRIMA ACCOGLIENZA</option>
        </select>
    </div>
</div>
<div class="container-narrow py-5">
    <app-high-charts *ngIf="chartPostiDisponibili" [options]="chartPostiDisponibili" [height]="'280px'"></app-high-charts>
</div>
