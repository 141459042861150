import {Directive, Input, ElementRef, HostListener, EventEmitter, Output} from '@angular/core';


@Directive({
  selector: '[appSort]'
})
export class SortDirective {

  @Input() appSort: Array<any>;
  @Output() sortProp = new EventEmitter();

  constructor(private targetElem: ElementRef) { }

  @HostListener('click')
  sortData(): void {

    // Get Reference Of Current Clicked Element
    const elem = this.targetElem.nativeElement;
    // Get In Which Order list should be sorted by default it should be set to desc on element attribute
    const order = elem.getAttribute('data-order');
    // Get The Property Name from Element Attribute
    const property = elem.getAttribute('data-name');

    // Reset all siblings order
    const S = this.getSiblings(elem);
    S.forEach(sibling => {
      sibling.removeAttribute('data-order');
    });

    // Do sort
    const neworder = order === 'desc' ? 'asc' : 'desc';
    elem.setAttribute('data-order', neworder);
    const ret = {};
    ret[property] = neworder + '_nulls_last';
    this.sortProp.emit(ret);
  }

  getSiblings(e): any[] {
    // for collecting siblings
    const siblings = [];
    // if no parent, return no sibling
    if (!e.parentNode) {
      return siblings;
    }
    // first child of the parent node
    let sibling  = e.parentNode.firstChild;
    // collecting siblings
    while (sibling) {
      if (sibling.nodeType === 1 && sibling !== e) {
        siblings.push(sibling);
      }
      sibling = sibling.nextSibling;
    }
    return siblings;
  }
}
