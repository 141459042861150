import {Injectable, OnInit} from '@angular/core';
import {Apollo} from 'apollo-angular';

import territori from '../graphql/territori.graphql';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private comBySlug = {};
  private provBySlug = {};
  private data: any;

  constructor(
    private apollo: Apollo,
  ) {
    // console.log('init singleton');
    // this.init();
  }

  init(): void {
    this.apollo
      .query<any>({query: territori})
      .subscribe(
        ({data, loading}) => {
          this.data = data;

          this.comBySlug = {};
          this.data.comuni.forEach(com => {
            this.comBySlug[this.slugify(com.denominazione)] = com;
          });

          this.data.province.forEach(prov => {
            this.provBySlug[this.slugify(prov.denominazione)] = prov;
          });
        }
      );
  }

  getTerritory(): Observable<any> {
    return this.apollo.query<any>({query: territori});
  }

  slugify = (...args: (string | number)[]): string => {
    const value = args.join(' ');

    return value
      .normalize('NFD') // split an accented letter in the base letter and the accent
      .replace(/[\u0300-\u036f]/g, '') // remove all previously split accents
      .toLowerCase()
      .trim()
      .replace(/[^a-z0-9 ]/g, '') // remove all chars not letters, numbers and spaces (to be replaced)
      .replace(/\s+/g, '-'); // separator
  }
}
