import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import * as Highcharts from 'highcharts';
import {AppConfig} from '../../app.config';

@Component({
  selector: 'app-panel-posti-disponibili',
  templateUrl: './panel-posti-disponibili.component.html',
  styleUrls: ['./panel-posti-disponibili.component.scss']
})
export class PanelPostiDisponibiliComponent implements OnInit {

  public _data: any = null;
  public chartPostiDisponibili: Highcharts.Options = null;

  @Input() set data(data: any) {
    if (data) {
      this._data = data;
      this.postiDisponibiliRefresh();
    }
  }

  public postiDisponibiliType: '' | 'CAS' | 'SAI' | 'PRIMA_ACCOGLIENZA' = '';

  constructor(
    public config: AppConfig,
  ) {
  }

  ngOnInit(): void {
  }

  postiDisponibiliRefresh(): void {
    let D;
    const optsPostiDisponibili = JSON.parse(JSON.stringify(this.config.chartPostiDisponibili));

    const classification = ['POLO', 'CINTURA', 'INTERMEDIO', 'PERIFERICO', 'ULTRAPERIFERICO', 'ALTRO'];

    switch (this.postiDisponibiliType) {
      case '':
        D = this._data.posti_classe_comuni_totale[0];
        // console.log(D);
        optsPostiDisponibili.xAxis.categories = D.POLO.map(item => item.year);
        classification.forEach((item, i) => {
          optsPostiDisponibili.series[i].data = D[item].map(subitem => subitem.posti_disponibili);
        });
        break;
      case 'CAS':
      case 'PRIMA_ACCOGLIENZA':
      case 'SAI':
        D = this._data.posti_classe_comuni_dett[0];
        // console.log(this.postiDisponibiliType, D);
        optsPostiDisponibili.xAxis.categories = D.POLO[this.postiDisponibiliType + '_' + 'POLO'].map(item => item.year);
        classification.forEach((item, i) => {
          optsPostiDisponibili.series[i].data = D[item][this.postiDisponibiliType + '_' + item].map(subitem => subitem.posti_disponibili);
        });
        break;
    }

    this.chartPostiDisponibili = optsPostiDisponibili;
  }
}
