import {Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, PLATFORM_ID} from '@angular/core';
import {DecimalPipe, isPlatformBrowser} from '@angular/common';
import {Router} from '@angular/router';

import maxBy from 'lodash-es/maxBy';

const chroma = require('chroma-js');

// GRAPHQL
import {Apollo} from 'apollo-angular';
import hometerritori from '@graphql/home-territori.graphql';
import strutture from '@graphql/strutture.graphql';
import {StruttureData} from '@models/strutture';
import {HomeTerritoriData} from '@models/home-territory';

class NumberOnlyPipe extends DecimalPipe {
  public transform(value): any {
    return super.transform(value, '1.0-0');
  }
}

@Component({
  selector: 'app-panel-territori',
  templateUrl: './panel-territori.component.html',
  styleUrls: ['./panel-territori.component.scss']
})
export class PanelTerritoriComponent implements OnInit, OnDestroy {
  private map: any;
  private popup: any;
  private currentYear = 2020;
  private mapIDProp: string;
  private mapNameProp: string;
  private loading: boolean;

  private markerShown = false;

  poi: StruttureData;
  private poiDone = false;

  // FILTRI TERRITORIO
  public territoryProp: 'posti_disponibili' | 'numero_strutture' | 'presenze' = 'posti_disponibili';
  public territoryCenterType: 'ALL' | ' SAI' | 'CAS' | 'PRIMA ACCOGLIENZA' = 'ALL';
  public territoryPropType: '' | '_perc_abitanti' = '';
  public dataBuffer = {COMUNI: null, PROVINCE: null};
  public dataById = {COMUNI: {}, PROVINCE: {}};

  private palette = ['#A4D0BC', '#30684E'];
  private numberPipe = new NumberOnlyPipe('it-IT');

  private currentMarkersById = {};
  private provinciaSelect: any = null;
  private comuneSelect: any = null;
  private strutturaSelect: any = null;

  private mouseMoveBind;
  private mouseLeaveBind;
  private mouseTerritoryClickBind;
  private mousePOIClickBind: any;

  public maxValue: number;
  public scalePow = {};

  @Input() showTerritoryAdmTypeSelect = true;
  @Input() territoryAdmType: 'PROVINCE' | 'COMUNI' = 'PROVINCE';
  private isBrowser: boolean;
  private chroma = chroma;


  @Input() set provincia(selection: any) {
    this.provinciaSelect = selection;
    this.getData();
  }

  @Input() set comune(selection: any) {
    this.comuneSelect = selection;
    this.getData();
  }

  @Input() set struttura(selection: any) {
    this.strutturaSelect = selection;
    this.getData();
  }

  BBox: number[];
  @Input() set bbox(coords: number[]) {
    this.BBox = coords;
    this.fitBounds();
  }

  @Input() maxYear: number = null;

  @Output() mapReady = new EventEmitter();

  constructor(
    private router: Router,
    private apollo: Apollo,
    @Inject(PLATFORM_ID) private platformId: object) {
    this.isBrowser = isPlatformBrowser(this.platformId);

  }

  ngOnInit(): void {
    this.getData();
  }

  ngOnDestroy(): void {
    this.unBindMouseEvents();
    this.removeAllPOI();
  }

  loadData(type: 'PROVINCE' | 'COMUNI'): void {
    this.apollo
      .query<HomeTerritoriData>({
        query: hometerritori, variables: {
          area_typology: type,
          typology: this.territoryCenterType
        }
      })
      .subscribe(
        ({data, loading}) => {
          this.dataBuffer[type] = data.territori.nodes;
          // console.log(this.dataBuffer[type]);
          this.loading = loading;
          const first = this.dataBuffer[type][0];
          const scale = first.posti_disponibili_perc_abitanti / (first.posti_disponibili / first.n_abitanti);
          // console.log(first.posti_disponibili_perc_abitanti, first.posti_disponibili, first.n_abitanti);
          this.scalePow[type] = (Math.pow(10, Math.ceil(Math.log10(scale / 5))));
          this.renderMap(type);
        }
      );
  }

  getData(): void {

    // ALWAYS LOAD PROVINCE DATA
    this.loadData('PROVINCE');

    // EVENTUALLY LOAD COMUNI DATA
    if (this.territoryAdmType === 'COMUNI') {
      this.loadData('COMUNI');
    }

    // LOAD POI BY CODE PROVINCIA
    if (this.provinciaSelect) {
      this.apollo
        .query<StruttureData>({query: strutture, variables: {code_provincia: this.provinciaSelect}})
        .subscribe(
          ({data, loading}) => {
            this.poi = data;
            this.loading = loading;
            this.renderPOI();
          }
        );
    }
  }

  renderMap(type): void {
    // console.log('renderMap', type, this.currentYear);
    // console.log(this.dataBuffer, this.map);
    if (this.dataBuffer[type] && this.map && this.currentYear) {
      const prop = this.territoryProp + this.territoryPropType;

      const max = maxBy(this.dataBuffer[type], prop)[prop];
      this.maxValue = max;
      this.mapIDProp = this.getMapIDProp(type);
      this.mapNameProp = this.getMapNameProp(type);


      // TERRITORI
      if (Array.isArray(this.dataBuffer[type])) {
        if (this.territoryAdmType === type) {
          const colorScale = this.chroma.scale(this.palette);
          const expression: any = ['match', ['get', this.mapIDProp]];
          const filteredItems = this.dataBuffer[type].filter(item => item.year === this.currentYear);

          filteredItems.forEach(row => {
            if (row.code_value) {
              this.dataById[type][row.code_value] = row;
              const rangeVal = (row[prop] / max);
              const color = colorScale(rangeVal).hex();
              expression.push(row.code_value, color);
            }
          });
          expression.push('rgba(0,0,0,0)');
          this.map.setPaintProperty(type, 'fill-color', expression);
        } else {
          this.dataBuffer[type].filter(item => item.year === this.currentYear).forEach(row => {
            if (row.code_value) {
              this.dataById[type][row.code_value] = row;
            }
          });
          this.map.setPaintProperty(type, 'fill-color', '#DADADA');
        }
      }

      this.highlightTerritory();
      this.fitBounds();
    }
  }

  highlightTerritory(): void {
    // console.log('pre-comuneSelect', this.comuneSelect);
    // console.log('pre-provinciaSelect', this.provinciaSelect);
    this.map.setLayoutProperty('COMUNI-highlight', 'visibility', 'none');
    this.map.setLayoutProperty('PROVINCE-highlight', 'visibility', 'none');
    if (this.comuneSelect !== null) {
        this.map.setFilter('COMUNI', ['==', 'COD_UTS', Number(this.provinciaSelect)]);
        this.map.setFilter('COMUNI-line', ['==', 'COD_UTS', Number(this.provinciaSelect)]);
        this.map.setFilter('COMUNI-highlight', ['==', 'PRO_COM', Number(this.comuneSelect)]);
        this.map.setLayoutProperty('COMUNI-highlight', 'visibility', 'visible');
    } else if (this.provinciaSelect !== null) {
      // this.map.jumpTo({center: [13.3977818, 42.1], zoom: 8});
        // this.onTerritoryAdmTypeChange('COMUNI');
        // this.map.setFilter('PROVINCE', ['==', 'COD_UTS', Number(this.mapFeatureSelect)]);
        this.map.setFilter('COMUNI', ['==', 'COD_UTS', Number(this.provinciaSelect)]);
        this.map.setFilter('COMUNI-line', ['==', 'COD_UTS', Number(this.provinciaSelect)]);
        this.map.setFilter('PROVINCE-highlight', ['==', 'COD_UTS', Number(this.provinciaSelect)]);
        this.map.setLayoutProperty('PROVINCE-highlight', 'visibility', 'visible');
    }
  }
  onMapReady(map: any): void {
    // territoryProp, territoryAdmType
    this.map = map;

    this.map.on('resize', () => {
      this.fitBounds();
    });
    if (!this.showTerritoryAdmTypeSelect) {
      this.map.setLayoutProperty('COMUNI', 'visibility', 'visible');
      this.map.setLayoutProperty('COMUNI-line', 'visibility', 'visible');
      this.map.setLayoutProperty('PROVINCE', 'visibility', 'visible');
      this.map.setLayoutProperty('PROVINCE-line', 'visibility', 'visible');
    }
    this.renderMap('PROVINCE');
    if (this.territoryAdmType === 'COMUNI') {
      this.renderMap('COMUNI');
    }
    this.renderPOI();
    this.bindMouseEvents();

    const mapboxgl = require('maplibre-gl');
    this.popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false
    });

    this.mapReady.emit(map);
  }

  private renderPOI(): void {

    if (this.isBrowser && this.poi && this.map) {
      const mapboxgl = require('maplibre-gl');
      this.removeAllPOI();
      this.poi.strutture.forEach(F => {
        if (this.territoryCenterType === 'ALL' || F.tipologia === this.territoryCenterType) {
          // create a HTML element for each feature
          const el = document.createElement('div');
          const currentYearData = F.relazioni.find(D => D.year === this.currentYear);
          if (currentYearData) {
            const tipologia = currentYearData.tipologia;
            // console.log(typeof this.comuneSelect);
            const additionalClass = this.comuneSelect && F.code_value !== Number(this.comuneSelect) ? ' outside-selected-territory' : '';
            el.className = 'marker-' + tipologia + additionalClass;

            const marker = new mapboxgl.Marker(el, {anchor: 'bottom'});
            const markerDiv = marker.getElement();
            markerDiv.addEventListener('mouseenter', () => {
              this.markerShown = true;
              marker.togglePopup();
              if (this.strutturaSelect && this.currentMarkersById[Number(this.strutturaSelect)]) {
                this.currentMarkersById[Number(this.strutturaSelect)].togglePopup();
              }
            });
            markerDiv.addEventListener('click', (e) => {
              e.stopPropagation();
              this.router.navigate(['/strutture/' + F.struttura_codice]);
            });
            markerDiv.addEventListener('mouseleave', () => {
              this.markerShown = false;
              marker.togglePopup();
              if (this.strutturaSelect  && this.currentMarkersById[Number(this.strutturaSelect)]) {
                this.currentMarkersById[Number(this.strutturaSelect)].togglePopup();
              }
            });

            marker.setLngLat([F.longitude, F.latitude])
              .setPopup(
                new mapboxgl.Popup({offset: 25}) // add popups
                  .setHTML(
                    `Struttura: ${F.name || 'dato non disponibile'}<hr>
                     Tipologia: ${tipologia || 'dato non disponibile'}<br>
                     Posti disponibili: ${currentYearData.posti_disponibili || 'dato non disponibile'}<br>
                     Presenze: ${currentYearData.presenze || 'dato non disponibile'}<br>
                    `
                  )
              ).addTo(this.map);
            this.currentMarkersById[F.struttura_codice] = marker;
          }
        }
      });

      if (this.isBrowser && this.strutturaSelect !== null) {
        const selectedPoi = this.poi.strutture.find(S => S.struttura_codice === Number(this.strutturaSelect));
        // console.log('selectedPoi', selectedPoi, this.currentMarkersById[Number(this.strutturaSelect)]);
        this.map.jumpTo({center: [selectedPoi.longitude, selectedPoi.latitude], zoom: 11});
        if (this.currentMarkersById[Number(this.strutturaSelect)]) {
          this.currentMarkersById[Number(this.strutturaSelect)].togglePopup();
        }
      }
      // this.poiDone = true;
    }
  }

  private removeAllPOI(): void {
    Object.keys(this.currentMarkersById).forEach(strutturaCodice => {
      this.currentMarkersById[strutturaCodice].remove();
    });
    this.currentMarkersById = {};

  }

  onMove(e): void {

    const F = e.features[0];
    const type = F.layer.id;
    const id = F.properties[this.getMapIDProp(type)];
    const name = F.properties[this.getMapNameProp(type)];

    if (!this.markerShown && this.dataById[type][id]) {
      this.map.getCanvas().style.cursor = 'pointer';
      const territoryType = type === 'PROVINCE' ? 'Provincia' : 'Comune';
      const Fdata = this.dataById[type][id];
      const postiDisponibili = this.numberPipe.transform(Fdata.posti_disponibili);
      const postiDisponibiliPerc = this.numberPipe.transform(Fdata.posti_disponibili_perc_abitanti);
      const presenze = this.numberPipe.transform(Fdata.presenze);
      const presenzePerc = this.numberPipe.transform(Fdata.presenze_perc_abitanti);
      const numeroStrutture = this.numberPipe.transform(Fdata.numero_strutture);
      const numeroAbitanti = this.numberPipe.transform(Fdata.n_abitanti);
      const scalePow = this.numberPipe.transform(this.scalePow[type]);
      const description = `
        ${territoryType}: ${name || 'dato non disponibile'}
        <hr>
        Numero abitanti: ${numeroAbitanti || 'dato non disponibile'}<br>
        Posti disponibili: ${postiDisponibili || 'dato non disponibile'}<br>
        Posti disponibili per ${scalePow} abitanti: ${postiDisponibiliPerc || 'dato non disponibile'}<br>
        Presenze: ${presenze || 'dato non disponibile'}<br>
        Presenze per ${scalePow} abitanti: ${presenzePerc || 'dato non disponibile'}<br>
        Numero di strutture: ${numeroStrutture || 'dato non disponibile'}<br>
        `;

      this.popup
        .setLngLat(e.lngLat)
        .setHTML(description)
        .addTo(this.map);
    } else {
      // console.log(type, id, name);
      this.popup.remove();
      this.map.getCanvas().style.cursor = '';
    }
  }

  onLeave(e): void {
    this.map.getCanvas().style.cursor = '';
    this.popup.remove();
    if (this.strutturaSelect) {
      this.currentMarkersById[Number(this.strutturaSelect)].togglePopup();
    }
  }

  onTerritoryClick(e): void {
    // console.log('onclick', e);
    const F = e.features[0];
    const type = F.layer.id;
    const mapIDProp = this.getMapIDProp(type);
    const id = F.properties[mapIDProp];
    if (this.dataById[type][id]) {
      window.scroll({
        top: 0,
        left: 0,
        // @ts-ignore
        behavior: 'instant'
      });

      this.router.navigate(['/' + String(type).toLowerCase() + '/' + id]);
    }
  }

  onPOIClick(e): void {
    // console.log('poi', e);
  }

  onYearChange($event: number): void {
    if ($event) {
      this.currentYear = $event;
      if (this.map) {
        this.renderMap(this.territoryAdmType);
        this.renderPOI();
      }
    }
  }

  onTerritoryPropChange($event: Event): void {
    // console.log(this.territoryProp);
    if (this.territoryProp === 'numero_strutture') {
      this.territoryPropType = '';
    }
    this.renderMap(this.territoryAdmType);
  }

  getMapIDProp(type: 'PROVINCE' | 'COMUNI'): string {
    return type === 'PROVINCE' ? 'COD_UTS' : 'PRO_COM';
  }

  getMapNameProp(type: 'PROVINCE' | 'COMUNI'): string {
    return type === 'PROVINCE' ? 'DEN_UTS' : 'COMUNE';
  }

  onTerritoryTypeChange($event: Event): void {
    this.getData();
  }

  onTerritoryPropTypeChange($event: any): void {
    this.renderMap(this.territoryAdmType);
  }

  onTerritoryAdmTypeChange($event: any): void {
    if ($event === 'COMUNI') {
      this.map.setLayoutProperty('COMUNI', 'visibility', 'visible');
      this.map.setLayoutProperty('COMUNI-line', 'visibility', 'visible');
      this.map.setLayoutProperty('PROVINCE', 'visibility', 'none');
      this.map.setLayoutProperty('PROVINCE-line', 'visibility', 'none');
    } else {
      this.map.setLayoutProperty('COMUNI', 'visibility', 'none');
      this.map.setLayoutProperty('COMUNI-line', 'visibility', 'none');
      this.map.setLayoutProperty('PROVINCE', 'visibility', 'visible');
      this.map.setLayoutProperty('PROVINCE-line', 'visibility', 'visible');
    }
    this.getData();
  }

  private bindMouseEvents(): void {
    if (this.map) {
      this.mouseMoveBind = this.onMove.bind(this);
      this.mouseLeaveBind = this.onLeave.bind(this);
      this.mouseTerritoryClickBind = this.onTerritoryClick.bind(this);
      this.mousePOIClickBind = this.onPOIClick.bind(this);

      this.map.on('mousemove', 'PROVINCE', this.mouseMoveBind);
      this.map.on('mousemove', 'COMUNI', this.mouseMoveBind);
      this.map.on('mouseleave', 'PROVINCE', this.mouseLeaveBind);
      this.map.on('mouseleave', 'COMUNI', this.mouseLeaveBind);
      this.map.on('click', 'PROVINCE', this.mouseTerritoryClickBind);
      this.map.on('click', 'COMUNI', this.mouseTerritoryClickBind);
    }
  }

  private unBindMouseEvents(): void {
    if (this.map) {
      this.map.off('mousemove', 'PROVINCE', this.mouseMoveBind);
      this.map.off('mousemove', 'COMUNI', this.mouseMoveBind);
      this.map.off('mouseleave', 'PROVINCE', this.mouseLeaveBind);
      this.map.off('mouseleave', 'COMUNI', this.mouseLeaveBind);
      this.map.off('click', 'PROVINCE', this.mouseTerritoryClickBind);
      this.map.off('click', 'COMUNI', this.mouseTerritoryClickBind);
    }
  }

  private fitBounds(): void {
    if (this.map && Array.isArray(this.BBox) && this.BBox.length) {
      this.map.fitBounds([this.BBox[1], this.BBox[0], this.BBox[3], this.BBox[2] ], {padding: 50, duration: 0});
    } else if (this.map){
      this.map.fitBounds([[6.626621, 35.492853 ], [18.520382, 47.091784]], {padding: 10, duration: 0});
    }
  }
}
