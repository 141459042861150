import {Component, Input, OnInit} from '@angular/core';
import {HomeData} from '@models/home';
import {AppConfig} from '../../app.config';
import * as Highcharts from 'highcharts';

@Component({
  selector: 'app-panel-numeri',
  templateUrl: './panel-numeri.component.html',
  styleUrls: ['./panel-numeri.component.scss']
})
export class PanelNumeriComponent implements OnInit {

  public _data: any = null;
  public iNumeriType: 'posti_disponibili' | 'presenze' | 'numero_strutture' = 'presenze';
  public chartNumeriAll: Highcharts.Options = null;
  public chartNumeriCAS: Highcharts.Options = null;
  public chartNumeriSAI: Highcharts.Options = null;
  public chartNumeriPRIMAACCOGLIENZA: Highcharts.Options = null;

  public SAIcompare: boolean;
  public CAScompare: boolean;
  public PRIMAACCOGLIENZAcompare: boolean;
  public ALLcompare: boolean;

  public SAIlastvalue: boolean;
  public CASlastvalue: boolean;
  public PRIMAACCOGLIENZAlastvalue: boolean;
  public ALLlastvalue: boolean;

  @Input() set data(data: any) {
    if (data) {
      // console.log(data);
      this._data = data;
      this.prepareTemplateData(this._data);
    }
  }

  constructor(
    public config: AppConfig
  ) { }

  ngOnInit(): void {
  }

  iNumeriTypeChange($event: MouseEvent): void {
    this.prepareTemplateData(this._data);
  }

  numeriSubjectChange($event: Event): void {

  }

  private prepareTemplateData(data: HomeData): void {

    const N = data.i_numeri[0];
    this.ALLcompare = data.aggregato_tutti_centri.length && data.aggregato_tutti_centri[data.aggregato_tutti_centri.length - 1]['perc_overall_y2y_' + this.iNumeriType] !== null;
    this.SAIcompare = N.SAI.length && N.SAI[N.SAI.length - 1]['perc_y2y_' + this.iNumeriType] !== null;
    this.CAScompare = N.CAS.length && N.CAS[N.CAS.length - 1]['perc_y2y_' + this.iNumeriType] !== null;
    this.PRIMAACCOGLIENZAcompare = N.PRIMA_ACCOGLIENZA.length && N.PRIMA_ACCOGLIENZA[N.PRIMA_ACCOGLIENZA.length - 1]['perc_y2y_' + this.iNumeriType] !== null;

    this.SAIlastvalue = N.SAI.length && N.SAI[N.SAI.length - 1][this.iNumeriType] !== null;
    this.CASlastvalue = N.CAS.length && N.CAS[N.CAS.length - 1][this.iNumeriType] !== null;
    this.PRIMAACCOGLIENZAlastvalue = N.PRIMA_ACCOGLIENZA.length && N.PRIMA_ACCOGLIENZA[N.PRIMA_ACCOGLIENZA.length - 1][this.iNumeriType] !== null;
    this.ALLlastvalue = data.aggregato_tutti_centri.length && data.aggregato_tutti_centri[data.aggregato_tutti_centri.length - 1][this.iNumeriType] !== null;

    const firstExistingCenterType = ['CAS', 'SAI', 'PRIMA_ACCOGLIENZA'].find(tipoCentro => {
      return N[tipoCentro] && N[tipoCentro].length;
    });
    // console.log('firstExistingCenterType', firstExistingCenterType);
    const optsNumeriAll = JSON.parse(JSON.stringify(this.config.chartNumeri));
    optsNumeriAll.xAxis.categories = N[firstExistingCenterType].map(item => item.year);
    optsNumeriAll.series[0].data = N.CAS.map(item => item[this.iNumeriType]);
    optsNumeriAll.series[1].data = N.SAI.map(item => item[this.iNumeriType]);
    optsNumeriAll.series[2].data = N.PRIMA_ACCOGLIENZA.map(item => item[this.iNumeriType]);
    this.chartNumeriAll = optsNumeriAll;
    // console.log('numeri', optsNumeriAll.categories, optsNumeriAll.series[0].data, optsNumeriAll.series[1].data,
    // optsNumeriAll.series[2].data);

    const optsNumeriCAS = JSON.parse(JSON.stringify(this.config.chartNumeriCAS));
    optsNumeriCAS.xAxis.categories = N.CAS.map(item => item.year);
    // console.log(optsNumeriCAS.categories);
    optsNumeriCAS.series[0].data = N.CAS.map(item => item[this.iNumeriType]);
    this.chartNumeriCAS = optsNumeriCAS;

    const optsNumeriSAI = JSON.parse(JSON.stringify(this.config.chartNumeriSAI));
    optsNumeriSAI.xAxis.categories = N.SAI.map(item => item.year);
    optsNumeriSAI.series[0].data = N.MSNA.map(item => item[this.iNumeriType]);
    optsNumeriSAI.series[1].data = N.ORD.map(item => item[this.iNumeriType]);
    optsNumeriSAI.series[2].data = N.DMDS.map(item => item[this.iNumeriType]);
    this.chartNumeriSAI = optsNumeriSAI;

    const optsNumeriPRIMAACCOGLIENZA = JSON.parse(JSON.stringify(this.config.chartNumeriPRIMAACCOGLIENZA));
    optsNumeriPRIMAACCOGLIENZA.xAxis.categories = N.PRIMA_ACCOGLIENZA.map(item => item.year);
    optsNumeriPRIMAACCOGLIENZA.series[0].data = N.CPA.map(item => item[this.iNumeriType]);
    optsNumeriPRIMAACCOGLIENZA.series[1].data = N.HOTSPOT.map(item => item[this.iNumeriType]);
    this.chartNumeriPRIMAACCOGLIENZA = optsNumeriPRIMAACCOGLIENZA;
  }

}
