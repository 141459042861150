import {Component, ElementRef, Inject, OnInit, PLATFORM_ID, ViewChild} from '@angular/core';
import {NgSelectConfig} from '@ng-select/ng-select';
import {NavigationEnd, Router} from '@angular/router';
import {isPlatformBrowser, LocationStrategy, ViewportScroller} from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'centri-ditalia';
  isPopState = false;

  @ViewChild('mainContent')
  private mainContentDiv!: ElementRef<HTMLElement>;
  private isBrowser: boolean;

  constructor(
    private config: NgSelectConfig,
    private router: Router,
    private locStrat: LocationStrategy,
    private viewPortScroller: ViewportScroller,
    @Inject(PLATFORM_ID) private platformId: object
  ) {
    this.config.placeholder = 'Cerca...';
    this.config.notFoundText = 'Non trovato';
    this.config.typeToSearchText = 'Scrivi per cercare';
    this.config.loadingText = 'Caricamento...';
    this.config.clearAllText = 'Cancella tutto';
    this.isBrowser = isPlatformBrowser(this.platformId);

  }

  ngOnInit(): void {
    this.locStrat.onPopState(() => {
      this.isPopState = true;
    });

    this.router.events.subscribe(event => {

      // Scroll to top if accessing a page, not via browser history stack
      if (event instanceof NavigationEnd && !this.isPopState) {
        // console.log('routechange', event);
        this.viewPortScroller.scrollToPosition([0, 0]);
        this.isPopState = false;
      }

      // Ensures that isPopState is reset
      if (event instanceof NavigationEnd) {
        this.isPopState = false;
      }
    });
  }

  onActivate($event: any): void {
    // Scrolling back to the top
    // Reference: https://stackoverflow.com/questions/48048299/angular-5-scroll-to-top-on-every-route-click/48048822
    if (this.isBrowser) {
      document.body.scrollTop = 0;
    }

/*    if (this.mainContentDiv) {
      (this.mainContentDiv.nativeElement as HTMLElement).scrollTop = 0;
    }*/
  }
}
