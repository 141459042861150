import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

// HIGH-CHARTS
import {HighchartsChartModule} from 'highcharts-angular';

import * as Highcharts from 'highcharts';
import {HighChartsComponent} from './high-charts/high-charts.component';
import {NgxMbpModule} from './ngx-mbp/ngx-mbp.module';
import {ButtonsModule} from 'ngx-bootstrap/buttons';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {PanelTerritoriComponent} from './panel-territori/panel-territori.component';
import {TabsModule} from 'ngx-bootstrap/tabs';
import {TimesliderComponent} from '@shared/timeslider/timeslider.component';
import {FormsModule} from '@angular/forms';
import {PanelNumeriComponent} from './panel-numeri/panel-numeri.component';
import {PanelImportiComponent} from '@shared/panel-importi/panel-importi.component';
import {BannerCosaSonoComponent} from '@shared/banner-cosa-sono/banner-cosa-sono.component';
import {PanelPostiDisponibiliComponent} from '@shared/panel-posti-disponibili/panel-posti-disponibili.component';
import {SortDirective} from '@services/sort.directive';

if (typeof Highcharts === 'object') {
  Highcharts.setOptions({
    lang: {
      loading: 'Sto caricando...',
      months: ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'],
      weekdays: ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato'],
      shortMonths: ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lugl', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'],
      rangeSelectorFrom: 'Da',
      rangeSelectorTo: 'A',
      rangeSelectorZoom: 'Periodo',
      downloadPNG: 'Download immagine PNG',
      downloadJPEG: 'Download immagine JPEG',
      downloadPDF: 'Download documento PDF',
      downloadSVG: 'Download immagine SVG',
      printChart: 'Stampa grafico',
      thousandsSep: '.',
      decimalPoint: ','
    }
  });
}


@NgModule({
  declarations: [
    HighChartsComponent,
    PanelTerritoriComponent,
    TimesliderComponent,
    PanelNumeriComponent,
    PanelImportiComponent,
    PanelPostiDisponibiliComponent,
    BannerCosaSonoComponent,
    SortDirective
  ],
  imports: [
    CommonModule,
    HighchartsChartModule,
    NgxMbpModule,
    ButtonsModule.forRoot(),
    TooltipModule.forRoot(),
    TabsModule.forRoot(),
    FormsModule,
  ],
  exports: [
    HighChartsComponent,
    NgxMbpModule,
    ButtonsModule,
    TooltipModule,
    TabsModule,
    PanelTerritoriComponent,
    PanelNumeriComponent,
    PanelImportiComponent,
    PanelPostiDisponibiliComponent,
    BannerCosaSonoComponent,
    SortDirective

  ]
})
export class SharedModule {
}
