<div class="container-fluid bg-green-gradient">
  <div class="container-xxl">
    <nav class="navbar navbar-expand-lg py-0 py-lg-2" aria-label="Main navigation">

      <a class="navbar-brand py-0 py-lg-3 " [routerLink]="['/']"><img src="/assets/img/svg/loghi/centri_ditalia_mappe_dellaccoglienza.svg"
                                                             alt="Centri D'Italia - mappe dell'accoglienza" width="260" height="82"></a>

      <div class="navbar-collapse offcanvas-collapse" [ngClass]="{open: !isCollapsed}" id="navbarNav" [collapse]="isCollapsed" [isAnimated]="true">
        <ul class="navbar-nav mx-auto mb-2 mb-lg-0">
          <li class="nav-item" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: true}">
            <a class="nav-link" aria-current="page" [routerLink]="['/']">Home</a>
          </li>
          <li class="nav-item dropdown" dropdown>
            <span class="nav-link dropdown-toggle" dropdownToggle id="dropdown01" data-bs-toggle="dropdown" aria-expanded="false">Territori</span>
            <ul class="dropdown-menu dropdown-menu-left p-0 rounded-3 opacity-95" *dropdownMenu aria-labelledby="dropdown01">
              <li class="rounded-3">
                <div class="scrollable-box fs-14 rounded-3" style="min-width: 210px;">
                  <div class="btn-group d-flex px-3 py-2 bg-lightgrey rounded-top">
                    <label class="btn btn-outline-secondary btn-sm rounded-start" [(ngModel)]="territoryType"
                           btnRadio="province" tabindex="0" role="button" (click)="territoryTypeChange($event)">Province</label>
                    <label class="btn btn-outline-secondary btn-sm rounded-end" [(ngModel)]="territoryType"
                           btnRadio="comuni" tabindex="0" role="button" (click)="territoryTypeChange($event)">Comuni</label>
                  </div>
                  <ng-select
                    class="dropdown-item p-1"
                    (click)="onClick($event)"
                    (change)="onChange($event)"
                    [items]="territori"
                    [virtualScroll]="true"
                    bindLabel="denominazione"
                    bindValue="id"
                    [(ngModel)]="selectedTerritorio"
                    [isOpen]="true"
                    appendTo=".scrollable-box">
                    <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                      <div [ngOptionHighlight]="search"
                           [innerHTML]="item['denominazione'] || item" [title]="item['denominazione'] || item"></div>
                    </ng-template>
                  </ng-select>
                </div>

              </li>
            </ul>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/pages/pubblicazioni']">Pubblicazioni</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/pages/open-data']">Open data</a>
          </li>
        </ul>
        <div class="d-lg-none position-absolute bottom-0">
          <div class="my-3">
            <span class="d-none d-md-inline-block me-3">Condividi su</span>
            <a href="https://twitter.com/intent/tweet?original_referer=https%3A%2F%2Fpublish.twitter.com%2F&ref_src=twsrc%5Etfw%7Ctwcamp%5Ebuttonembed%7Ctwterm%5Eshare%7Ctwgr%5E&text=Centri%20d%E2%80%99Italia%20-%20Mappe%20dell%E2%80%99accoglienza%20&url=https%3A%2F%2Fcentriditalia.it%2Fhome&via=openpolis%20%40ActionAidItalia" target="_blank" rel="noopener" class="text-body d-inline-block me-2"><i class="fab fa-twitter" alt="Twitter"></i></a>
            <a href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fcentriditalia.it%2Fhome&amp;src=sdkpreparse" target="_blank" rel="noopener" class="text-body"><i class="fab fa-facebook-f" alt="Facebook"></i></a>
          </div>
          <div class="d-flex mb-3">
            <a href="https://www.actionaid.it/" rel="noopener" class="d-inline-block me-4 mt-3"><img
              src="/assets/img/svg/loghi/actionaid_148x34.svg" width="148" height="34" alt="Actionaid - realizza il cambiamento" class="img-fluid"></a>
            <a href="https://www.openpolis.it" rel="noopener" class="mt-3"><img src="/assets/img/svg/loghi/openpolis_155x25.svg" width="155" height="25"
                                                                                alt="openpolis" class="img-fluid"></a>
          </div>
        </div>
      </div>

      <div class="text-white">
        <div class="text-end mb-lg-4">
          <span class="d-none d-lg-inline-block me-3">Condividi su</span>
          <a href="https://twitter.com/intent/tweet?original_referer=https%3A%2F%2Fpublish.twitter.com%2F&ref_src=twsrc%5Etfw%7Ctwcamp%5Ebuttonembed%7Ctwterm%5Eshare%7Ctwgr%5E&text=Centri%20d%E2%80%99Italia%20-%20Mappe%20dell%E2%80%99accoglienza%20&url=https%3A%2F%2Fcentriditalia.it%2Fhome&via=openpolis%20%40ActionAidItalia"
             target="_blank" rel="noopener" class="d-none d-lg-inline-block text-white me-2"><i class="fab fa-twitter" alt="Twitter"></i></a>
          <a href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fcentriditalia.it%2Fhome&amp;src=sdkpreparse"
             target="_blank" rel="noopener" class="d-none d-lg-inline-block text-white"><i class="fab fa-facebook-f" alt="Facebook"></i></a>
          <button class="navbar-toggler ms-auto border-0" type="button"
                  data-toggle="collapse"
                  data-target="#navbarNav"
                  (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed"
                  aria-controls="navbarNav" aria-label="Apri/Chiudi Navigazione"
          >
        <span class="ms-4 navbar-toggler-icon">
               <i class="fas fa-bars text-white"></i>
        </span>
          </button>
        </div>
        <div class="bg-credits d-none d-lg-flex">
          <a href="https://www.actionaid.it/" rel="noopener" class="d-inline-block ms-118 me-4 mt-3"><img
            src="/assets/img/svg/loghi/actionaid_148x34.svg" width="148" height="34" alt="Actionaid - realizza il cambiamento"></a>
          <a href="https://www.openpolis.it" rel="noopener" class="mt-3"><img src="/assets/img/svg/loghi/openpolis_155x25.svg" width="155" height="25"
                                                                              alt="openpolis"></a>
        </div>
      </div>
    </nav>
  </div>
</div>
