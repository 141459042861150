import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {NotFoundComponent} from './views/not-found/not-found.component';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: 'home',
    loadChildren: () => import('./views/home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'province',
    loadChildren: () => import('./views/provincia/provincia.module').then(m => m.ProvinciaModule)
  },
  {
    path: 'comuni',
    loadChildren: () => import('./views/comune/comune.module').then(m => m.ComuneModule)
  },
  {
    path: 'strutture',
    loadChildren: () => import('./views/struttura/struttura.module').then(m => m.StrutturaModule)
  },
  {
    path: 'pages',
    loadChildren: () => import('./views/pages/pages.module').then(m => m.PagesModule)
  },
  { path: '**', pathMatch: 'full',
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
