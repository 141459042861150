import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {NgcCookieConsentService, NgcInitializeEvent, NgcNoCookieLawEvent, NgcStatusChangeEvent} from 'ngx-cookieconsent';

import territori from '../../graphql/territori.graphql';
import {Apollo} from 'apollo-angular';
import {Router} from '@angular/router';
import {ApiService} from '../../services/api.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  public isCollapsed = true;

  private alive = true;

  selectedTerritorio: number;

  territori = [

  ];

  private popupOpenSubscription: Subscription;
  private popupCloseSubscription: Subscription;
  private initializeSubscription: Subscription;
  private statusChangeSubscription: Subscription;
  private revokeChoiceSubscription: Subscription;
  private noCookieLawSubscription: Subscription;

  private data: any;
  private loading = true;

  territoryType = 'province';
  public comBySlug = {};
  public proBySlug = {};

  constructor(
    private apollo: Apollo,
    private router: Router,
    private api: ApiService,
    private ccService: NgcCookieConsentService
  ) { }

  ngOnInit(): void {
    this.apollo
      .query<any>({query: territori})
      .subscribe(
        ({data, loading}) => {
          this.data = data;
          this.territori = this.data[this.territoryType];
          this.loading = loading;
          // console.log(this.data);
        }
      );

    // subscribe to cookieconsent observables to react to main events
    this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(
      () => {
      });

    this.popupCloseSubscription = this.ccService.popupClose$.subscribe(
      () => {
      });

    this.initializeSubscription = this.ccService.initialize$.subscribe(
      (event: NgcInitializeEvent) => {
      });

    this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
      (event: NgcStatusChangeEvent) => {
      });

    this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(
      () => {
      });

    this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe(
      (event: NgcNoCookieLawEvent) => {
      });
  }

  ngOnDestroy(): void {

    this.alive = false;
    // unsubscribe to cookieconsent observables to prevent memory leaks
    this.popupOpenSubscription.unsubscribe();
    this.popupCloseSubscription.unsubscribe();
    this.initializeSubscription.unsubscribe();
    this.statusChangeSubscription.unsubscribe();
    this.revokeChoiceSubscription.unsubscribe();
    this.noCookieLawSubscription.unsubscribe();
  }

  onClick($event: MouseEvent): void {
    $event.stopPropagation();
    // console.log($event);
  }

  territoryTypeChange($event: MouseEvent): void {
    $event.stopPropagation();
    this.selectedTerritorio = null;
    this.territori = this.data[this.territoryType];
  }

  onChange($event: any): void {
    this.isCollapsed = true;
    this.selectedTerritorio = null;
    this.router.navigate(['/' + this.territoryType + '/' + $event.code_value]);
  }
}
