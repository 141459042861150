import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxMbpComponent } from './ngx-mbp.component';

import { TrapScrollDirective } from './directives/trap-scroll.directive';

@NgModule({
  declarations: [NgxMbpComponent, TrapScrollDirective],
  exports: [
    NgxMbpComponent,
  ],
  imports: [
    CommonModule,
  ],
})
export class NgxMbpModule { }
