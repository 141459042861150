import {environment} from '../environments/environment';
import {Injectable} from '@angular/core';
import * as Highcharts from 'highcharts';

@Injectable({providedIn: 'root'})
export class AppConfig {
  chartNumeri: Highcharts.Options = {
    chart: {
      type: 'column',
      height: 280,
      backgroundColor: 'transparent'
    },
    title: {
      text: ''
    },
    colors: ['#1ca460', '#7f540d', '#db4c40'],
    xAxis: {
      categories: ['2018', '2019', '2020'],
      lineColor: '#8c8c8c',
      lineWidth: 1,
      labels: {
        style: {fontSize: '18px', color: '#00223b'}
      }
    },
    yAxis: {
      reversedStacks: false,
      lineWidth: 0,
      minorTickLength: 0,
      tickLength: 0,
      gridLineWidth: 0,
      min: 0,
      labels: {
        enabled: false
      },
      stackLabels: {
        enabled: true,
        style: {fontSize: '16px', color: '#00223b', fontWeight: 'normal'}
      },
      title: {
        text: ''
      },
    },
    legend: {
      enabled: false
    },
    tooltip: { outside: true },
    credits: {
      enabled: false
    },
    plotOptions: {
      column: {
        pointWidth: 56,
        stacking: 'normal',
        borderWidth: 0
      }
    },
    series: [
      {
        name: 'CAS',
        // @ts-ignore
        data: [0]
      },
      {
        name: 'SAI',
        // @ts-ignore
        data: [0]
      },
      {
        name: 'Prima accoglienza',
        // @ts-ignore
        data: [0]
      }
      ]
  };
  chartNumeriStruttura: Highcharts.Options = {
    chart: {
      type: 'column',
      height: 280,
      backgroundColor: 'transparent'
    },
    title: {
      text: ''
    },
    tooltip: {
      shared: true
    },
    colors: ['#00223b', '#1ca460'],
    xAxis: {
      categories: ['2018', '2019', '2020'],
      lineColor: '#8c8c8c',
      lineWidth: 1,
      labels: {
        style: {fontSize: '18px', color: '#00223b'}
      }
    },
    yAxis: {
      reversedStacks: false,
      lineWidth: 0,
      lineColor: 'transparent',
      minorTickLength: 0,
      tickLength: 0,
      gridLineWidth: 0,
      min: 0,
      labels: {
        enabled: false
      },
      title: {
        text: ''
      },
    },
    legend: {
      enabled: false
    },
    credits: {
      enabled: false
    },
    plotOptions: {
      column: {
        pointWidth: 28,
        borderWidth: 0
      }
    },
    series: [
      {
        name: 'Presenze',
        // @ts-ignore
        data: [0]
      },
      {
        name: 'Posti disponibili',
        // @ts-ignore
        data: [0]
      }
    ]
  };
  chartNumeriCAS: Highcharts.Options = {
    chart: {
      type: 'column',
      height: 280,
      backgroundColor: 'transparent'
    },
    title: {
      text: ''
    },
    colors: ['#1ca460', '#3fef97'],
    xAxis: {
      categories: ['2018', '2019', '2020'],
      lineColor: '#8c8c8c',
      lineWidth: 1,
      labels: {
        style: {fontSize: '18px', color: '#00223b'}
      }
    },
    yAxis: {
      reversedStacks: false,
      lineWidth: 0,
      minorTickLength: 0,
      tickLength: 0,
      gridLineWidth: 0,
      min: 0,
      labels: {
        enabled: false
      },
      stackLabels: {
        enabled: true,
        style: {fontSize: '16px', color: '#00223b', fontWeight: 'normal'}
      },
      title: {
        text: ''
      },
    },
    legend: {
      enabled: false
    },
    tooltip: { outside: true },
    credits: {
      enabled: false
    },
    plotOptions: {
      column: {
        pointWidth: 56,
        stacking: 'normal',
        borderWidth: 0
      }
    },
    series: [
      {
        name: 'CAS',
        // @ts-ignore
        data: [0]
      }
    ]
  };
  chartNumeriSAI: Highcharts.Options = {
    chart: {
      type: 'column',
      height: 280,
      backgroundColor: 'transparent'
    },
    title: {
      text: ''
    },
    colors: ['#7f540d', '#b47712', '#503508'],
    xAxis: {
      categories: ['2018', '2019', '2020'],
      lineColor: '#8c8c8c',
      lineWidth: 1,
      labels: {
        style: {fontSize: '18px', color: '#00223b'}
      }
    },
    yAxis: {
      reversedStacks: false,
      lineWidth: 0,
      minorTickLength: 0,
      tickLength: 0,
      gridLineWidth: 0,
      min: 0,
      labels: {
        enabled: false
      },
      stackLabels: {
        enabled: true,
        style: {fontSize: '16px', color: '#00223b', fontWeight: 'normal'}
      },
      title: {
        text: ''
      },
    },
    legend: {
      enabled: false
    },
    tooltip: { outside: true },
    credits: {
      enabled: false
    },
    plotOptions: {
      column: {
        pointWidth: 56,
        stacking: 'normal',
        borderWidth: 0
      }
    },
    series: [
      {
        name: 'MSNA',
        // @ts-ignore
        data: [0]
      },
      {
        name: 'ORD',
        // @ts-ignore
        data: [0]
      },
      {
        name: 'DMDS',
        // @ts-ignore
        data: [0]
      },
    ]
  };
  chartNumeriPRIMAACCOGLIENZA: Highcharts.Options = {
    chart: {
      type: 'column',
      height: 280,
      backgroundColor: 'transparent'
    },
    title: {
      text: ''
    },
    colors: ['#db4c40', '#fc7166'],
    xAxis: {
      categories: ['2018', '2019', '2020'],
      lineColor: '#8c8c8c',
      lineWidth: 1,
      labels: {
        style: {fontSize: '18px', color: '#00223b'}
      }
    },
    yAxis: {
      reversedStacks: false,
      lineWidth: 0,
      minorTickLength: 0,
      tickLength: 0,
      gridLineWidth: 0,
      min: 0,
      labels: {
        enabled: false
      },
      stackLabels: {
        enabled: true,
        style: {fontSize: '16px', color: '#00223b', fontWeight: 'normal'}
      },
      title: {
        text: ''
      },
    },
    legend: {
      enabled: false
    },
    tooltip: { outside: true },
    credits: {
      enabled: false
    },
    plotOptions: {
      column: {
        pointWidth: 56,
        stacking: 'normal',
        borderWidth: 0
      }
    },
    series: [
      {
        name: 'CPA',
        // @ts-ignore
        data: [0]
      },
      {
        name: 'HOTSPOT',
        // @ts-ignore
        data: [0]
      }
    ]
  };
  chartImporti: Highcharts.Options = {
    chart: {
      type: 'column',
      height: 280,
      backgroundColor: 'transparent'
    },
    title: {
      text: ''
    },
    colors: ['#8B1E3F', '#DB4C40', '#1CA460'],
    xAxis: {
      categories: ['2018', '2019', '2020'],
      lineColor: '#8c8c8c',
      lineWidth: 1,
      labels: {
        style: {fontSize: '18px', color: '#00223b'}
      }
    },
    yAxis: {
      reversedStacks: false,
      lineWidth: 0,
      lineColor: 'transparent',
      minorTickLength: 0,
      tickLength: 0,
      gridLineWidth: 0,
      min: 0,
      labels: {
        enabled: false
      },
      title: {
        text: ''
      },
    },
    legend: {
      verticalAlign: 'top'
    },
    credits: {
      enabled: false
    },
    plotOptions: {
      line: {
        marker: {
          enabled: false
        }
      },
      column: {
        pointWidth: 28,
        borderWidth: 0
      },
      series: {
        connectNulls: true
      }
    },
    series: [
      {
        name: 'CAS',
        // @ts-ignore
        data: [0],
        stack: 'CAS'
      },
      {
        name: 'PRIMA ACCOGLIENZA',
        // @ts-ignore
        data: [0],
        stack: 'PRIMA ACCOGLIENZA'
      },
      {
        type: 'line',
        name: 'MEDIA',
        // @ts-ignore
        data: [0],
      }
    ]
  };
  chartImportiStruttura: Highcharts.Options = {
    chart: {
      type: 'column',
      height: 280,
      backgroundColor: 'transparent'
    },
    title: {
      text: ''
    },
    colors: ['#cccccc'],
    xAxis: {
      categories: ['2018', '2019', '2020'],
      lineColor: '#8c8c8c',
      lineWidth: 1,
      labels: {
        style: {fontSize: '18px', color: '#00223b'}
      }
    },
    yAxis: {
      reversedStacks: false,
      lineWidth: 0,
      lineColor: 'transparent',
      minorTickLength: 0,
      tickLength: 0,
      gridLineWidth: 0,
      min: 0,
      labels: {
        enabled: false
      },
      title: {
        text: ''
      },
    },
    legend: {
      enabled: false
    },
    credits: {
      enabled: false
    },
    plotOptions: {
      line: {
        marker: {
          enabled: false
        }
      },
      column: {
        pointWidth: 28,
        borderWidth: 0
      },
      series: {
        connectNulls: true
      }
    },
    series: [
      {
        name: '',
        // @ts-ignore
        data: [0]
      }
    ]
  };
  chartPostiDisponibili: Highcharts.Options = {
    chart: {
      type: 'bar',
      height: 280,
      backgroundColor: 'transparent'
    },
    title: {
      text: ''
    },
    colors: ['#1CA460', '#8B1E3F', '#DB4C40', '#f0a587', '#F0C987', '#3C153B'],
    xAxis: {
      categories: ['2018', '2019', '2020'],
      lineWidth: 0,
      lineColor: 'transparent',
      minorTickLength: 0,
      tickLength: 0,
      gridLineWidth: 0,
      labels: {
        style: {fontSize: '18px', color: '#00223b'}
      }
    },
    yAxis: {
      reversedStacks: false,
      lineWidth: 0,
      lineColor: 'transparent',
      minorTickLength: 0,
      tickLength: 0,
      gridLineWidth: 0,
      min: 0,
      labels: {
        enabled: false,
      },
      title: {
        text: ''
      },
      stackLabels: {
        enabled: true,
        crop: false,
        style: {fontSize: '16px', color: '#00223b', fontWeight: 'normal'}
      }
    },
    legend: {
      symbolHeight: 12,
      symbolWidth: 12,
      symbolRadius: 0,
      verticalAlign: 'top',
      align: 'left'
    },
    credits: {
      enabled: false
    },
    plotOptions: {
      bar: {
        pointWidth: 30,
        stacking: 'normal'
      }
    },
    series: [
      {
        name: 'POLO',
        // @ts-ignore
        data: [0]
      },
      {
        name: 'CINTURA',
        // @ts-ignore
        data: [0]
      },
      {
        name: 'INTERMEDIO',
        // @ts-ignore
        data: [0]
      },
      {
        name: 'PERIFERICO',
        // @ts-ignore
        data: [0]
      },
      {
        name: 'ULTRAPERIFERICO',
        // @ts-ignore
        data: [0]
      },
      {
        name: 'ALTRO',
        // @ts-ignore
        data: [0]
      } ]
  };
}
