import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-banner-cosa-sono',
  templateUrl: './banner-cosa-sono.component.html',
  styleUrls: ['./banner-cosa-sono.component.scss']
})
export class BannerCosaSonoComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
