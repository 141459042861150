<div class="container-narrow mt-5 mb-4">
  <div class="d-flex justify-content-center flex-wrap">
    <select class="form-select form-select-sm w-auto me-1 me-md-4 mb-2" aria-label="Seleziona tipo di dato" [(ngModel)]="territoryProp" (ngModelChange)="onTerritoryPropChange($event)">
      <option value="posti_disponibili">Posti disponibili</option>
      <option value="presenze">Presenze</option>
      <option value="numero_strutture">N° strutture</option>
    </select>

    <select class="form-select form-select-sm w-auto me-1 me-md-4 mb-2" aria-label="Seleziona tipo di centro" [(ngModel)]="territoryCenterType" (ngModelChange)="onTerritoryTypeChange($event)">
      <option value="ALL">Tutti i centri</option>
      <option value="CAS">CAS</option>
      <option value="SAI">SAI</option>
      <option value="PRIMA ACCOGLIENZA">PRIMA ACCOGLIENZA</option>
    </select>
    <select *ngIf="showTerritoryAdmTypeSelect" class="form-select form-select-sm w-auto me-1 me-md-4 mb-2" aria-label="Seleziona livello amministrativo" [(ngModel)]="territoryAdmType" (ngModelChange)="onTerritoryAdmTypeChange($event)">
      <option value="PROVINCE">Province</option>
      <option value="COMUNI">Comuni</option>
    </select>
    <select *ngIf="territoryProp !== 'numero_strutture'" class="form-select form-select-sm w-auto me-1 me-md-4 mb-2" aria-label="Seleziona valore" [(ngModel)]="territoryPropType" (ngModelChange)="onTerritoryPropTypeChange($event)">
      <option value="">Numero assoluto</option>
      <option value="_perc_abitanti">Per {{scalePow[territoryAdmType] | number}} abitanti</option>
    </select>
  </div>
</div>

<div class="container-narrow">
  <div class="d-flex justify-content-between">
    <app-timeslider [minYear]="2018" [maxYear]="maxYear" (value)="onYearChange($event)"></app-timeslider>
    <div>
      <div class="gradient-territori d-flex justify-content-between bg-scale-0-100">
        <span>0</span><span>{{maxValue}}</span>
      </div>
    </div>
  </div>
</div>
<ngx-mbp
  #mapImporti
  [mapstyleurl]="'/assets/mapstyles/home.json'"
  [height]="'650px'"
  [navigationCompass]="false"
  [navigationControl]="true"
  [navigationZoom]="true"
  [fullscreenControl]="false"
  [scaleControl]="true"
  (mapReady)="onMapReady($event)"
></ngx-mbp>
