import {Component, Input, OnInit} from '@angular/core';

import * as Highcharts from 'highcharts';

@Component({
  selector: 'app-high-charts',
  templateUrl: './high-charts.component.html',
  styleUrls: ['./high-charts.component.scss']
})
export class HighChartsComponent implements OnInit {
  @Input() set options(opts: Highcharts.Options) {
    this.shadowOptions = opts;
    this.chartUpdateFlag = true;
  }
  @Input() width = '100%';
  @Input() height = '400px';

  public chartUpdateFlag = false;
  public shadowOptions: Highcharts.Options;
  public isHighcharts = typeof Highcharts === 'object';
  public Highcharts: typeof Highcharts = Highcharts;

  constructor() {
    if (this.isHighcharts) {
      Highcharts.setOptions({
        chart: {
          style: {
            fontFamily: 'thesansplain'
          }
        }
      });
    }
  }

  ngOnInit(): void {
  }

}
