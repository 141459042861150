<ng-template #noCompare>&nbsp;</ng-template>
<ng-template #invariato>
  invariato
</ng-template>
<div class="container-fluid text-center">
  <div class="btn-group px-3 py-4 rounded-top">
    <label class="btn btn-outline-secondary rounded-start" [(ngModel)]="iNumeriType"
           btnRadio="posti_disponibili" tabindex="0" role="button" (click)="iNumeriTypeChange($event)">Posti disponibili</label>
    <label class="btn btn-outline-secondary" [(ngModel)]="iNumeriType"
           btnRadio="presenze" tabindex="0" role="button" (click)="iNumeriTypeChange($event)">Presenze</label>
    <label class="btn btn-outline-secondary rounded-end" [(ngModel)]="iNumeriType"
           btnRadio="numero_strutture" tabindex="0" role="button" (click)="iNumeriTypeChange($event)">N° di strutture</label>
  </div>
</div>
<div class="bg-lightgrey">
  <tabset [justified]="true" class="no-tab-padding" (change)="numeriSubjectChange($event)" *ngIf="_data !== null">
    <tab *ngIf="_data.aggregato_tutti_centri.length">
      <ng-template tabHeading>
        <div class="text-body">Tutti i centri
          <ng-template #nolastvalueALL><span class="h3 blue">&nbsp;n.d. </span><br>&nbsp;</ng-template>
          <ng-container *ngIf="ALLlastvalue; else nolastvalueALL;">
          <span class="h3 blue">
              {{ _data.aggregato_tutti_centri[_data.aggregato_tutti_centri.length - 1][iNumeriType] | number }}</span><br>
            {{ _data.aggregato_tutti_centri[_data.aggregato_tutti_centri.length - 1]['perc_overall_y2y_' + iNumeriType] > 0 ? '+' : '' }}
            <ng-container *ngIf="ALLcompare; else noCompare;">
              <ng-container
                *ngIf="_data.aggregato_tutti_centri[_data.aggregato_tutti_centri.length-1]['perc_overall_y2y_' + iNumeriType] !== 0; else invariato"
              >{{ _data.aggregato_tutti_centri[_data.aggregato_tutti_centri.length - 1]['perc_overall_y2y_' + iNumeriType] | number }}
                %
              </ng-container>
              rispetto al
              {{ _data.aggregato_tutti_centri[_data.aggregato_tutti_centri.length - 1].year - 1 }}
            </ng-container>
          </ng-container>
        </div>
      </ng-template>
      <div class="container-mini py-5">
        <app-high-charts [options]="chartNumeriAll" [height]="'280px'" style="height: 280px"></app-high-charts>
      </div>
    </tab>

    <tab *ngIf="_data.i_numeri[0].CAS.length">
      <ng-template tabHeading>
        <div class="text-body">CAS
          <i class="info-grey"
             [tooltip]="'I Centri di accoglienza straordinaria (CAS) dovrebbero essere attivati in via eccezionale in mancanza di posti negli altri tipi di centro.'"></i>
          <ng-template #nolastvalueCAS><span class="h3 green">&nbsp;n.d. </span><br>&nbsp;</ng-template>
          <ng-container *ngIf="CASlastvalue; else nolastvalueCAS;">
          <span class="h3 green">
              {{ _data.i_numeri[0].CAS[_data.i_numeri[0].CAS.length - 1][iNumeriType] | number }}</span><br>
            {{ _data.i_numeri[0].CAS[_data.i_numeri[0].CAS.length - 1]['perc_y2y_' + iNumeriType] > 0 ? '+' : '' }}
            <ng-container *ngIf="CAScompare; else noCompare">
              <ng-container
                *ngIf="_data.i_numeri[0].CAS[_data.i_numeri[0].CAS.length-1]['perc_y2y_' + iNumeriType] !== 0; else invariato">
                {{ _data.i_numeri[0].CAS[_data.i_numeri[0].CAS.length - 1]['perc_y2y_' + iNumeriType] | number }}%
              </ng-container>

              rispetto al
              {{ _data.i_numeri[0].CAS[_data.i_numeri[0].CAS.length - 1].year - 1 }}
            </ng-container>
          </ng-container>
        </div>
      </ng-template>
      <div class="container-mini py-5">
        <app-high-charts [options]="chartNumeriCAS" [height]="'280px'" style="height: 280px"></app-high-charts>
      </div>
    </tab>

    <tab *ngIf="_data.i_numeri[0].SAI.length">
      <ng-template tabHeading>
        <div class="text-body">SAI
          <i class="info-grey"
             [tooltip]="'Il Sistema di accoglienza e integrazione (SAI già SIPROIMI / SPRAR) è il sistema ordinario di accoglienza.'"></i>
          <ng-template #nolastvalueSAI><span class="h3 brown">&nbsp;n.d. </span><br>&nbsp;</ng-template>
          <ng-container *ngIf="SAIlastvalue; else nolastvalueSAI;">
          <span class="h3 brown">
          {{ _data.i_numeri[0].SAI[_data.i_numeri[0].SAI.length - 1][iNumeriType] | number }}</span><br>
            {{ _data.i_numeri[0].SAI[_data.i_numeri[0].SAI.length - 1]['perc_y2y_' + iNumeriType] > 0 ? '+' : '' }}
            <ng-container *ngIf="SAIcompare; else noCompare">
              <ng-container
                *ngIf="_data.i_numeri[0].SAI[_data.i_numeri[0].SAI.length-1]['perc_y2y_' + iNumeriType] !== 0; else invariato">
                {{ _data.i_numeri[0].SAI[_data.i_numeri[0].SAI.length - 1]['perc_y2y_' + iNumeriType] | number }}%
              </ng-container>
              rispetto al
              {{ _data.i_numeri[0].SAI[_data.i_numeri[0].SAI.length - 1].year - 1 }}
            </ng-container>
          </ng-container>
        </div>
      </ng-template>
      <div class="container-mini py-5">
        <app-high-charts [options]="chartNumeriSAI" [height]="'280px'" style="height: 280px"></app-high-charts>
      </div>
    </tab>

    <tab *ngIf="_data.i_numeri[0].PRIMA_ACCOGLIENZA.length">
      <ng-template tabHeading>
        <div class="text-body">Prima accoglienza
          <i class="info-grey"
             [tooltip]="'Nei centri di prima accoglienza si svolgono le operazioni di identificazione e le persone vengono indirizzate verso le successive fasi di accoglienza.'"></i>
          <ng-template #nolastvalueAccoglienza><span class="h3 orange">&nbsp;n.d. </span><br>&nbsp;</ng-template>
          <ng-container
            *ngIf="PRIMAACCOGLIENZAlastvalue; else nolastvalueAccoglienza;">
          <span class="h3 orange">
          {{ _data.i_numeri[0].PRIMA_ACCOGLIENZA[_data.i_numeri[0].PRIMA_ACCOGLIENZA.length - 1][iNumeriType] | number }}</span><br>
            {{ _data.i_numeri[0].PRIMA_ACCOGLIENZA[_data.i_numeri[0].PRIMA_ACCOGLIENZA.length - 1]['perc_y2y_' + iNumeriType] > 0 ? '+' : '' }}
            <ng-container *ngIf="PRIMAACCOGLIENZAcompare; else noCompare;">
              <ng-container
                *ngIf="_data.i_numeri[0].PRIMA_ACCOGLIENZA[_data.i_numeri[0].PRIMA_ACCOGLIENZA.length-1]['perc_y2y_' + iNumeriType] !== 0; else invariato">
                {{ _data.i_numeri[0].PRIMA_ACCOGLIENZA[_data.i_numeri[0].PRIMA_ACCOGLIENZA.length - 1]['perc_y2y_' + iNumeriType] | number }}
                %
              </ng-container>
              rispetto al
              {{ _data.i_numeri[0].PRIMA_ACCOGLIENZA[_data.i_numeri[0].PRIMA_ACCOGLIENZA.length - 1].year - 1 }}
            </ng-container>
          </ng-container>

        </div>
      </ng-template>
      <div class="container-mini py-5">
        <app-high-charts [options]="chartNumeriPRIMAACCOGLIENZA" [height]="'280px'"
                         style="height: 280px"></app-high-charts>
      </div>
    </tab>
  </tabset>
</div>
