// ANGULAR
import {Inject, LOCALE_ID, NgModule, PLATFORM_ID} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClientModule, HttpHeaders} from '@angular/common/http';
import {BrowserModule, BrowserTransferStateModule, makeStateKey, TransferState} from '@angular/platform-browser';
import {isPlatformBrowser, registerLocaleData} from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// ENVIRONMENT
import {environment} from '../environments/environment';

// SET LOCALE
import localeIt from '@angular/common/locales/it';
registerLocaleData(localeIt, 'it');

// COCKIE CONSENT
const cookieConfig: NgcCookieConsentConfig = {
  enabled: false,
  cookie: {
    domain: 'centriditalia.hantarex.org'
  },
  position: 'bottom',
  theme: 'block',
  palette: {
    popup: {
      background: '#000000',
      text: '#ffffff',
      link: '#ffffff'
    },
    button: {
      background: '#f1d600',
      text: '#000000',
      border: 'transparent'
    }
  },
  type: 'info',
  content: {
    message: 'Informativa<br> Questo sito o gli strumenti terzi da questo utilizzati si avvalgono di cookie necessari al funzionamento ed utili alle finalità illustrate nella privacy policy. Se vuoi saperne di più o negare il consenso, consulta la cookie policy. Chiudendo questo banner, acconsenti all’uso dei cookie.',
    dismiss: 'Accetto',
    deny: 'Rifiuto',
    link: 'Privacy policy',
    href: '/pages/privacy-policy',
    policy: 'Cookie Policy'
  }
};
import { NgcCookieConsentConfig, NgcCookieConsentModule} from 'ngx-cookieconsent';

// APOLLO
const STATE_KEY = makeStateKey<any>('apollo.state');
const uri = environment.baseAPIUri; // <-- add the URL of the GraphQL server here
import {HasuraRoleInterceptor} from './services/hasura-role.interceptor';
import {InMemoryCache, NormalizedCacheObject} from '@apollo/client/core';
import {HttpLink, HttpLinkHandler} from 'apollo-angular/http';
import {Apollo} from 'apollo-angular';

// font awesome pro
import { library, dom } from '@fortawesome/fontawesome-svg-core';

import {
  faPlay, faPause, faQuestionCircle, faChevronRight, faChevronDown, faLink, faBars
} from '@fortawesome/pro-solid-svg-icons';

import {
  faTwitter, faFacebookF
} from '@fortawesome/free-brands-svg-icons';


library.add(
  faPlay, faPause, faQuestionCircle, faChevronRight, faChevronDown, faLink, faBars, faTwitter, faFacebookF
);
dom.watch();

// APP
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutModule } from './layout/layout.module';
import { ApiService } from './services/api.service';
import {GoogleAnalyticsGTagComponent} from '@shared/google-analytics-gtag/google-analytics-gtag.component';
import {NotFoundComponent} from './views/not-found/not-found.component';

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    GoogleAnalyticsGTagComponent
  ],
  imports: [
    BrowserModule.withServerTransition({appId: 'serverApp'}),
    BrowserTransferStateModule,
    AppRoutingModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
    BrowserAnimationsModule,
    LayoutModule,
    HttpClientModule
  ],
  providers: [
    {provide: LOCALE_ID, useValue: 'it-IT'},
    {provide: HTTP_INTERCEPTORS, useClass: HasuraRoleInterceptor, multi: true },
    ApiService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  cache: InMemoryCache;
  link: HttpLinkHandler;

  constructor(
    private readonly apollo: Apollo,
    private readonly transferState: TransferState,
    private readonly httpLink: HttpLink,
    @Inject(PLATFORM_ID) readonly platformId: object
  ) {
    // tells if it's browser or server
    const isBrowser = isPlatformBrowser(platformId);
    // const headers = new HttpHeaders().set('x-hasura-role', 'migranti');

    this.cache = new InMemoryCache({addTypename: false});
    this.link = this.httpLink.create({ uri });

    this.apollo.create({
      link: this.link,
      cache: this.cache,
      ...(isBrowser
        ? {
          // queries with `forceFetch` enabled will be delayed
          ssrForceFetchDelay: 200,
        }
        : {
          // avoid to run twice queries with `forceFetch` enabled
          ssrMode: true,
        }),
    });

    if (isBrowser) {
      this.onBrowser();
    } else {
      this.onServer();
    }
  }

  onServer(): void {
    // serializes the cache and puts it under a key
    // console.log('onserver');
    this.transferState.onSerialize(STATE_KEY, () => this.cache.extract());
  }

  onBrowser(): void {
    // reads the serialized cache
    // console.log('onbrowser');
    const state = this.transferState.get<NormalizedCacheObject>(STATE_KEY, null);
    // and puts it in the Apollo
    this.cache.restore(state);
  }
}
