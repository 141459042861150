import {Component, Input, OnInit} from '@angular/core';
import {HomeData} from '@models/home';
import {AppConfig} from '../../app.config';
import * as Highcharts from 'highcharts';

@Component({
  selector: 'app-panel-importi',
  templateUrl: './panel-importi.component.html',
  styleUrls: ['./panel-importi.component.scss']
})
export class PanelImportiComponent implements OnInit {

  public _data: any = null;
  public chartImporti: Highcharts.Options = null;

  @Input() set data(data: any) {
    if (data) {
      this._data = data;
      this.prepareTemplateData(this._data);
    }
  }

  constructor(
    public config: AppConfig
  ) { }

  ngOnInit(): void {
  }

  private prepareTemplateData(data: HomeData): void {
/*    const firstExistingCenterType = ['CAS', 'PRIMA_ACCOGLIENZA'].find(tipoCentro => {
      return data.importi[0][tipoCentro] && data.importi[0][tipoCentro].length;
    });
    console.log('firstExistingCenterType', firstExistingCenterType);*/
    const optsImporti = JSON.parse(JSON.stringify(this.config.chartImporti));

    optsImporti.xAxis.categories = data.aggregato_importi.map(item => item.year);
    optsImporti.series[0].data = data.importi[0].CAS.map(item => item.media_costo_procapite_al_giorno);
    optsImporti.series[1].data = data.importi[0].PRIMA_ACCOGLIENZA.map(item => item.media_costo_procapite_al_giorno);
    optsImporti.series[2].data = data.aggregato_importi.map(item => item.media_costo_procapite_al_giorno_overall_y2y);
    this.chartImporti = optsImporti;
  }
}
