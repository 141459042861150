import {AfterViewInit, Component, ElementRef, EventEmitter, Inject, Input, NgZone, OnChanges, OnDestroy, OnInit, Output,
  PLATFORM_ID, SimpleChanges, ViewChild} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { MapboxPlusChoroplet } from './extensions/choroplet';

type position = 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';
type distanceUnit = 'imperial' |  'metric' |  'nautical';

@Component({
  // tslint:disable-next-line
  selector: 'ngx-mbp',
  template: `
      <ng-content></ng-content>
      <div #container id="{{mapId}}" class="ngx-mbp-container" [ngStyle]="{width: '100%', height: height}"></div>
  `,
  styleUrls: ['./ngx-mbp.component.scss']
})
export class NgxMbpComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  public mapId = 'ngx-mbp-container-' + Math.random().toString(36).substring(2);
  public isBrowser: boolean;

  //
  // Navigation controls
  // https://docs.mapbox.com/mapbox-gl-js/api/#navigationcontrol
  //
  @Input() navigationControl = true;
  @Input() navigationZoom = true;
  @Input() navigationCompass = true;
  @Input() navigationPosition: position = 'top-left';
  //
  // Scale controls
  // https://docs.mapbox.com/mapbox-gl-js/api/#scalecontrol
  //
  @Input() scaleControl = true;
  @Input() scaleMaxWidth = 100;
  @Input() scaleUnit: distanceUnit = 'metric';
  @Input() scalePosition: position = 'bottom-left';
  //
  // Full screen controls
  // https://docs.mapbox.com/mapbox-gl-js/api/#fullscreencontrol
  //
  @Input() fullscreenControl = true;
  @Input() fullscreenPosition: position = 'top-left';

  @Input() height = '60vh';
  @Output() mapReady = new EventEmitter();

  public map;
  public maplibregl;
  public mapEl: HTMLElement;
  private mapStyle;
  private mapEvents: any = {};
  private scrollTimeout = null;

  // estensions
  public choroplet: MapboxPlusChoroplet;

  @ViewChild('container', { static: false }) mapContainer: ElementRef;
  @Input() mapstyleurl: object | string;

  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    public zone: NgZone,
    private http: HttpClient
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    if (this.isBrowser && !this.map && this.mapStyle) {
      this.createMap(this.mapStyle);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (this.isBrowser) {
      if (changes.mapstyleurl) {
        this.loadStyle(changes.mapstyleurl.currentValue);
      }

      if (this.map) {
        if (changes.height) {
          this.map.resize();
        }
      }
    }
  }

  ngOnDestroy(): void {
    if (this.isBrowser) {
      this.unbindMouse();
      this.map.remove();
    }
  }

  public loadStyle(styleurl): void {
    this.http.get(styleurl).subscribe( res => {
      this.mapStyle = res;
      this.createMap(this.mapStyle);

      if (!this.choroplet) {
        this.choroplet = new MapboxPlusChoroplet();
      }
    });
  }

  createMap(mapStyle): void {
    this.mapEl = this.mapContainer.nativeElement;
    this.maplibregl = require('maplibre-gl');

    this.map = new this.maplibregl.Map({
      container: this.mapId,
      style: mapStyle,
      center: mapStyle.center || [0, 0], // starting position [lng, lat]
      zoom: mapStyle.zoom || 0, // starting zoom
      pitchWithRotate: false,
      dragRotate: false,
      touchZoomRotate: false,
      maxPitch: 0,
      interactive: typeof mapStyle.interactive === 'undefined' ? true : Boolean(mapStyle.interactive),
      maxBounds: Array.isArray(mapStyle.maxBounds) ? mapStyle.maxBounds : null
    });

    // disable map rotation using right click + drag
    this.map.dragRotate.disable();

// disable map rotation using touch rotation gesture
    this.map.touchZoomRotate.disableRotation();

    this.bindMouse();

    // Navigation controls
    if (this.navigationControl) {
      this.addNavigationControl({showCompass: this.navigationCompass, showZoom: this.navigationZoom}, this.navigationPosition);
    }
    // Scale controls
    if (this.scaleControl) {
      this.addScaleControl({ maxWidth: this.scaleMaxWidth, unit: this.scaleUnit }, this.scalePosition);
    }
    // Full screen controls
    if (this.fullscreenControl) {
      this.addFullscreenControl(this.fullscreenPosition);
    }

    this.map.on('load', () => {
      this.mapReady.emit(this.map);
    });

  }

  destroyMap(): void {

  }

  bindMouse(): void {
    // map scroll event
    this.map.scrollZoom.disable();
    if (this.mapStyle.interactive === undefined || this.mapStyle.interactive === true) {
      this.mapEvents.scroll = this.onScroll.bind(this);

      // console.log('bindMouse', this.zone, this.mapEl);
      this.zone.runOutsideAngular(() => {
        this.mapEl.parentElement.addEventListener('mousewheel', this.mapEvents.scroll);
        this.mapEl.parentElement.addEventListener('DOMMouseScroll', this.mapEvents.scroll);
      });
    }
  }

  private unbindMouse(): void {
    this.zone.runOutsideAngular(() => {
      this.mapEl.parentElement.removeEventListener('mousewheel', this.mapEvents.scroll);
      this.mapEl.parentElement.removeEventListener('DOMMouseScroll', this.mapEvents.scroll);
    });
  }

  private onScroll($event: MouseEvent): void {
    // console.log('onScroll', $event);

    this.zone.runOutsideAngular(() => {
      $event.stopPropagation();
      clearTimeout(this.scrollTimeout);

      if ($event.ctrlKey === true) {
        // CTRL Key is down
        $event.preventDefault();
        this.map.scrollZoom.enable();
        this.mapEl.classList.remove('map-scroll');
        this.scrollTimeout = setTimeout(() => {
          this.map.scrollZoom.disable();
        }, 1000);
      } else {
        // NO CTRL Key
        this.map.scrollZoom.disable();
        this.mapEl.classList.add('map-scroll');
        this.scrollTimeout = setTimeout(() => {
          this.mapEl.classList.remove('map-scroll');
        }, 1000);
      }
    });
  }

  public addNavigationControl(options, pos: position): void {
    const navControl = new this.maplibregl.NavigationControl(options);
    this.map.addControl(navControl, pos);
  }

  public addScaleControl(options, pos: position): void {
    const scaleControl = new this.maplibregl.ScaleControl(options);
    this.map.addControl(scaleControl, pos);
  }

  public addFullscreenControl(pos: position): void {
    const fullScreenControl = new this.maplibregl.FullscreenControl();
    this.map.addControl(fullScreenControl, pos);
  }
}
