<div *ngIf="dateRange.length" class="d-flex">
  <div class="d-inline-block mr-2 mt-4 mb-5">
    <button type="button" class="btn btn-link btn-circle btn-sm green" (click)="togglePlay($event)" *ngIf="!playInterval"><i class="fas fa-play"></i></button>
    <button type="button" class="btn btn-link btn-circle btn-sm green" (click)="togglePlay($event)" *ngIf="playInterval"><i class="fas fa-pause"></i></button>
  </div>
  <div class="slidecontainer">
    <div class="position-relative" style="height: 29px">
      <div class="position-absolute text-nowrap font-weight-semi-bold p-2" [style]="{left: currentValuePerc + '%', transform: 'translateX(-' + currentValuePerc + '%)' }">{{dateRange[currentValue]}}</div>
    </div>
    <div>
      <input class="slider" #slider type="range"
             [step]="step" min="0" [max]="dateRange.length-1" (input)="changeValue(slider.value)" [value]="currentValue">
    </div>
    <div class="date-limits position-relative overflow-visible">
      <div class="font-weight-semi-bold">{{dateRange[0]}}</div>
      <div class="font-weight-semi-bold">{{dateRange[dateRange.length-1]}}</div>
    </div>
  </div>
</div>

