import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';


@Component({
  selector: 'app-timeslider',
  templateUrl: './timeslider.component.html',
  styleUrls: ['./timeslider.component.scss']
})
export class TimesliderComponent implements OnInit, OnChanges {
  @Input() minYear: number;
  @Input() maxYear: number;
  @Input() step = 1;
  @Input() interval = 1000;
  @Input() loop = false;
  @Output() value: EventEmitter<number> = new EventEmitter<number>();

  public currentDate: number;
  public dateRange: number[] = [];
  public currentValue = 0;
  public currentValuePerc = 0;

  private playInterval = null;

  constructor() { }

  ngOnInit(): void {

  }

  ngOnChanges($event: SimpleChanges): void {
    if (this.minYear && this.maxYear) {
      this.dateRange = this.createDateRange(this.minYear, this.maxYear);
    }
    this.changeValue(String(this.dateRange.length - 1));
  }

  togglePlay($event): void {
    $event.preventDefault();
    if (this.playInterval) {
      clearInterval(this.playInterval);
      this.playInterval = null;
    } else {
      if (this.currentValue + this.step >= this.dateRange.length) {
        this.changeValue(String(0));
      }
      this.playInterval = setInterval(() => {
        if (this.currentValue + this.step < this.dateRange.length) {
          this.changeValue(String(this.currentValue + this.step));
        } else {
          // this.changeValue('0');
          this.togglePlay($event);
        }
      }, this.interval);
    }

  }

  createDateRange(min: number, max: number): number[] {
    const dates = [];
    // you can iterate over it
    for (let i = min; i <= max; i++) {
      dates.push(i);
    }

// or construct an array out of it
    /*    const arr = Array.from(days(interval));
        console.log(arr);*/

    return dates;
  }

  public changeValue(value: string): void {
    this.currentValue = Number(value);
    this.currentValuePerc = (this.currentValue / (this.dateRange.length - 1)) * 100;
    this.value.emit(this.dateRange[this.currentValue]);
  }

  public onDrag($event: DragEvent): void {
    // console.log($event);
  }
}
