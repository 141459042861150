<footer id="footer">
  <div class="container-fluid bg-green-gradient py-30">
    <div class="container-xxl">
      <div class="row justify-content-between">
        <div class="col-md-6">
          <a href="/"><img src="/assets/img/svg/loghi/centri_ditalia_mappe_dellaccoglienza.svg" alt="Centri D'Italia - mappe dell'accoglienza"
                           width="260" height="82"></a>
        </div>
        <div class="col-md-6 d-flex justify-content-end">
          <div class="text-white">
            <div class="text-md-end mb-4">
              <ul class="list-inline list-separator list-separator-light mb-0">
                <li class="list-inline-item">
                  <a class="link-light" [routerLink]="['/pages/licenza']">Licenza di utilizzo</a>
                </li>
                <li class="list-inline-item">
                  <a class="link-light" [routerLink]="['/pages/open-data']">Open data</a>
                </li>
                <li class="list-inline-item">
                  <a class="link-light" [routerLink]="['/pages/FAQ']">FAQ</a>
                </li>
                <li class="list-inline-item">
                  <a class="link-light" [routerLink]="['/pages/privacy-policy']">Privacy policy</a>
                </li>
                <li class="list-inline-item">
                  <a href="#" aria-label="Preferenze dei cookie" class="cc-link link-light" data-cc="c-settings">Preferenze cookies</a>
                </li>
                <li class="list-inline-item">
                  <a class="link-light" [routerLink]="['/pages/contatti']">Contatti</a>
                </li>
              </ul>
            </div>
          </div>
          <div class="d-none d-lg-block d-flex  bg-credits">
            <a href="https://www.actionaid.it/" rel="noopener" class="d-inline-block me-4 ms-10 mt-3"><img
              src="/assets/img/svg/loghi/actionaid_148x34.svg" alt="Actionaid - realizza il cambiamento" width="148" height="34"></a>
            <a class="mt-3" href="https://www.openpolis.it/" rel="noopener"><img src="/assets/img/svg/loghi/openpolis_155x25.svg" alt="openpolis"
                                                                                 width="155" height="25">
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="d-lg-none d-flex credits_md">
    <a href="https://www.actionaid.it/" rel="noopener" class="d-inline-block me-4 ms-10 mt-3"><img src="/assets/img/svg/loghi/actionaid_148x34.svg" alt="Actionaid - realizza il cambiamento" width="148" height="34"></a>
    <a href="https://www.openpolis.it/" rel="noopener" class="mt-3" ><img src="/assets/img/svg/loghi/openpolis_155x25.svg" alt="openpolis" width="155" height="25"></a>
  </div>
</footer>


